<template>
  <section class="box-height" style="margin-bottom: 70px">
    <div
      class="box-title clearfix"
      style="padding: 20px 10px; border: 1px #e5e5e5 solid"
    >
      <el-col
        :span="6"
        style="
          border: 1px solid rgb(237, 237, 237);
          padding: 10px;
          height: 100%;
        "
      >
        <el-input
          style="margin-bottom: 10px; border-radius: 15px"
          placeholder="输入关键字进行过滤"
          @keyup.enter.native="search()"
          class="setInput"
          v-model="filterText"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="search()"
          ></el-button>
        </el-input>
        <div class="filterCon">
          <el-tree
            v-if="isTree"
            class="filter-tree"
            :data="data"
            :expand-on-click-node="false"
            @node-click="handleNodeClick"
            :props="defaultProps"
            default-expand-all
            ref="tree"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span :title="node.label">{{ node.label }}</span>
              <span class="operator" style="position: absolute; right: 20px">
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <i class="el-icon-more"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="editOrCreate(data, 1)"
                      >新增子节点</el-dropdown-item
                    >
                    <el-dropdown-item @click.native="editOrCreate(data, 3)"
                      >新增节点</el-dropdown-item
                    >
                    <el-dropdown-item @click.native="editOrCreate(data, 2)"
                      >编辑</el-dropdown-item
                    >
                    <el-dropdown-item @click.native="NodeDel(data)"
                      >删除</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
            </span>
          </el-tree>
          <div class="group" v-else>
            <div class="wrapperScope" v-for="(i,index) in options" :key="index">
              <p class="groupTitle">{{ i.type }}</p>
              <ul class="groupContent" v-for="(m,index1) in i.searchDeptVo" :key="index1">
                <li @click="getVal(m.id, m.type)">{{ m.name }}</li>
              </ul>
            </div>
          </div>
          <p
            style="text-align: center; color: #666666"
            v-if="options.length == 0 && !isTree"
          >
            <img src="../../assets/noData.png" alt="" />
          </p>
        </div>
      </el-col>
      <el-col
        :span="18"
        class="clearfix"
        style="padding-left: 15px; height: 100%"
      >
        <el-col :span="24">
          <h3 style="color: #333333; margin-top: 3px; margin-bottom: 15px">
            {{ titleFoo }} ( {{ pagination.total }}人 )
            <el-button
              type="default"
              plain
              size="mini"
              @click="addNew"
              style="margin-left: 10px"
            >
              <i class="el-icon-plus"></i>添加用户
            </el-button>
            <el-button
              type="default"
              :disabled="isDisableChioce"
              plain
              size="mini"
              @click="addNew('bulk')"
              style="margin-left: 10px"
            >
              <i class="el-icon-plus"></i>批量转移组织
            </el-button>
          </h3>
        </el-col>
        <el-col
          :span="24"
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
          "
        >
          <div style="display: flex; align-items: center">
            <div style="display: flex; align-items: center; margin-left: 8px">
              <p>角色：</p>
              <el-select
                v-model="roleIds"
                placeholder="请选择"
                clearable
                filterable
                multiple
                collapse-tags
                style="width: 240px"
                @change="searchList"
              >
                <el-option
                  v-for="(item, index) in rolesList"
                  :key="'a_' + index"
                  :value="item.roleId"
                  :label="item.roleName"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <el-pagination
            :hide-on-single-page="pagination.total > 10 ? false : true"
            v-if="pagination.total > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pagination.pageSize"
            style="padding-right: 0; float: right"
            layout="total,  prev, pager, next, sizes"
            :total="pagination.total"
          ></el-pagination>
        </el-col>
        <el-table
          :data="ListData"
          class="tabBorder custor clearfix"
          :header-row-style="{ height: '36px', margin: '0' }"
          :header-cell-style="{
            'background-color': '#f5f7fa',
          }"
          v-loading="listLoading"
          ref="handSelectTest_multipleTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="40"
            align="center"
          ></el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop="nickName"
            label="员工姓名"
            align="left"
          ></el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop="userName"
            label="账户"
            align="left"
          ></el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop="departmentName"
            label="组织框架"
            align="left"
          ></el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop="roleName"
            label="角色"
            align="left"
          ></el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop
            label="创建时间"
            align="left"
            width="160"
          >
            <template slot-scope="scope">{{
              scope.row.createdTime.substring(0, 19)
            }}</template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop
            align="left"
            width="120"
          >
            <template slot="header">
              <el-tooltip placement="left-start" popper-class="tooltipStyle">
                <div slot="content">
                  <div class="tipTitle">说明</div>
                  <div class="tipContent">
                    1.开启对应账户状态：正常
                    <br />（可正常使用系统） <br />2.关闭对应账户状态：停用
                    <br />（无法登录系统）
                  </div>
                </div>
                <span class="wenhao"
                  ><i
                    class="iconfont iconwenhao-xiangsu"
                    style="font-size: 14px; margin-left: 4px"
                  ></i
                ></span>
              </el-tooltip>
              是否启用
            </template>
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status"
                active-color="#ff3f3f"
                @change="ChangeStatus(scope.row)"
                inactive-color="#ddd"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop
            label="操作"
            fixed="right"
            align="left"
            width="120"
          >
            <template slot-scope="scope">
              <span @click="handleEdit(scope.row.id, scope.row)" class="tabHref"
                >修改</span
              >
              <el-divider direction="vertical"></el-divider>
              <span
                :disabled="!scope.row.status"
                @click="handleLoginAs(scope.row.id)"
                class="tabHref"
                v-has="'setting:user:loginothers'"
                >登录</span
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :hide-on-single-page="pagination.total > 10 ? false : true"
          v-if="pagination.total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.pageNo"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pagination.pageSize"
          style="padding-right: 0"
          layout="total,  prev, pager, next, sizes"
          :total="pagination.total"
        ></el-pagination>
      </el-col>
    </div>
    <el-dialog
      :title="titName"
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
      width="600px"
      :before-close="handleClose"
    >
      <el-form
        @submit.native.prevent
        :model="createDate"
        ref="createDate"
        :rules="rules"
        label-width="120px"
      >
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="组织架构名称：" prop="name">
              <el-input
                v-model.trim="createDate.name"
                maxlength="20"
                placeholder="请输入不要超过20个字"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="主管：" prop="managerId">
              <el-select
                v-model="createDate.managerId"
                placeholder="请选择"
                style="width: 100%"
                filterable
                clearable
              >
                <el-option
                  v-for="item in UserBaseList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="needStatistics" class="nowS" label="业绩统计：">
              <el-radio-group v-model="createDate.needStatistics">
                <el-radio :label="true">设置</el-radio>
                <el-radio :label="false">不设置</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="businessType" class="nowS" label="组织标签：">
              <el-radio-group v-model="createDate.businessType">
                <el-radio label="WHITE_COLLAR">白领</el-radio>
              </el-radio-group>
              <el-tooltip
                    placement="bottom"
                    effect="dark"
                  >
                  <div slot="content">涉及逻辑：<br/>  1、offer员工自动同步至紫牛；<br/>   2、单独一套offer至入职的保温流程；</div>
                    <i class="iconfont icon-wenhao-xiangsu" style="margin-left:5px"></i>
                  </el-tooltip>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24" style="text-align: center">
            <el-form-item label-width="0"></el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="cancel()">取消</el-button>
        <el-button
          type="primary"
          size="mini"
          @click="save('createDate')"
          :loading="SaveLoading"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="titNameUser"
      :visible.sync="dialogVisibleUser"
      :modal-append-to-body="false"
      append-to-body
      custom-class="settingDialog"
      :close-on-click-modal="false"
      width="720px"
      :before-close="handleCloseUser"
    >
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="cancelUser()">取 消</el-button>
        <el-button
          type="primary"
          size="mini"
          :loading="saveLoadingUser"
          @click="saveUser('userData')"
          >保存</el-button
        >
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          v-if="!isBulk"
          label="*用户信息"
          name="first"
          style="max-height: 360px; overflow-y: auto; overflow-x: hidden"
        >
          <el-form
            :validate-on-rule-change="false"
            @submit.native.prevent
            :model="userData"
            ref="userData"
            :rules="rulesUser"
            label-width="135px"
          >
            <el-row>
              <p
                style="
                  border: 1px solid #ffdddc;
                  padding: 10px 20px;
                  color: #cc6666 !important;
                  background-color: #fff5f4;
                  margin: 0 0 10px;
                "
              >
                提示：账户创建后，系统会自动给账户发送创建提示邮件
              </p>
              <el-col :span="24">
                <el-form-item label="账户：" prop="username">
                  <el-input
                    v-model="userData.username"
                    :disabled="disabled"
                    placeholder="请输入账户（邮箱）"
                    style="width: 300px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="员工姓名：" prop="nickName">
                  <el-input
                    v-model.trim="userData.nickName"
                    placeholder="请输入英文名+中文姓（不超过21个字）"
                    maxlength="21"
                    style="width: 300px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="角色：" prop="roleId">
                  <el-select
                    v-model="userData.roleId"
                    placeholder="请选择"
                    clearable
                    @change="rolesId"
                    filterable
                    style="width: 300px"
                  >
                    <el-option
                      v-for="item in RoleBaseList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="直接主管：" prop="managerId">
                  <el-select
                    v-model="userData.managerId"
                    placeholder="请选择"
                    clearable
                    filterable
                    style="width: 300px"
                  >
                    <el-option
                      v-for="item in UserBaseList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="默认首页：" prop="path">
                  <el-select
                    v-model="userData.path"
                    style="width: 300px"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="(item, index) in optionsData"
                      :key="index"
                      :label="item.name"
                      :value="item.path"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24" v-if="needPwd">
                <el-form-item label="重置密码" prop="password">
                  <el-input
                    v-model="userData.password"
                    placeholder="请输入重置密码（首次创建时请忽略）"
                    maxlength="30"
                    style="width: 300px"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane
          label="*组织框架"
          name="third"
          style="max-height: 360px; overflow-y: auto; overflow-x: hidden"
        >
          <el-tree
            class="treeClass"
            :data="OrganizinList"
            :props="defaultProps"
            :default-checked-keys="userData.depIds"
            node-key="id"
            ref="SlotOrgList"
            show-checkbox
            check-strictly
          >
            <span class="slot-t-node" slot-scope="{ node, data }">
              <span>
                <i
                  v-show="!data.children || data.children.length == 0"
                  style="margin-right: 10px"
                ></i>
                <span>
                  {{ node.label }}
                  <a v-if="data.type == '集团'">(集团)</a>
                  <a v-else-if="data.type == '地区'">(地区)</a>
                  <a v-else-if="data.type == '团队'">(团队)</a>
                  <a v-else-if="data.type == '子团队'">(子团队)</a>
                </span>
              </span>
            </span>
          </el-tree>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </section>
</template>


<script>
import {
  addOrganizin,
  deleteDepById,
  getDepById,
  getUserBaseInfos,
  OrganizinList,
  serachGroup,
  getUserList,
  getUser,
  addUser,
  getDepartmentList,
  getRoleBaseInfos,
  loginAs,
  getCurrentUser,
  putUser,
  roleGetAll,
  userBatchShiftDept,
  sysUserIndexOptions, //获取当前用户可设置的主页列表
} from "../../api/api";
import Cookies from "js-cookie";
export default {
  name: "addressbook",
  data() {
    var validatePass = (rule, value, callback) => {
      const reg = /[\u4e00-\u9fa5]/g;
      if (reg.test(value)) {
        return callback(new Error("密码不能设置为中文"));
      }
      callback();
    };
    return {
      filterText: "",
      titName: "",
      activeName: "first",
      titNameUser: "",
      value: "",
      clickId: "",
      type: "",
      isTree: true,
      dialogVisible: false,
      isShowMenu: false,
      SaveLoading: false,
      saveLoadingUser: false,
      dialogVisibleUser: false,
      listLoading: false,
      data: [],
      ListData: [],
      titleFoo: "",
      number: 0,
      RoleBaseList: [],
      UserBaseList: [],
      OrganizinList: [],
      DATA: "",
      pagination: {
        total: 0,
        pageNo: 1,
        pageSize: 50,
      },
      createDate1: {
        seatNum: "",
        seatPassword: "",
      },
      userData: {
        id: "",
        username: "",
        password: "",
        nickName: "",
        version: 0,
        roleId: "",
        status: true,
        depIds: [],
        managerId: null,
        seatNum: "",
        seatPassword: "",
        path: "",
      },
      disabled: false,
      needPwd: false,
      createDate: {
        id: "",
        name: "",
        parentId: null,
        type: null,
        managerId: null,
        version: 0,
        needStatistics: 0,
        businessType:null
      },
      options: [],
      rulesUser: {
        username: [
          {
            required: true,
            trigger: "blur",
            message: "请输入账户",
          },
          { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" },
        ],
        nickName: [
          {
            required: true,
            trigger: "blur",
            message: "请填写员工姓名",
          },
        ],
        roleId: [
          {
            required: true,
            trigger: "change",
            message: "请选择角色",
          },
        ],
        managerId: [
          {
            required: true,
            trigger: "change",
            message: "请选择直接主管",
          },
        ],
        password: [
          {
            trigger: "blur",
            validator: validatePass,
          },
        ],
      },
      rules: {
        name: [
          {
            required: true,
            trigger: "blur",
            message: "请填写组织架构名称",
          },
        ],
        managerId: [
          {
            required: true,
            trigger: "change",
            message: "请选择主管",
          },
        ],
        needKpi: [
          {
            required: true,
            trigger: "change",
          },
        ],
      },
      defaultProps: {
        children: "children",
        label: "name",
      },
      roleIds: [],
      rolesList: [],
      isBulk: false,
      multipleSelection: [],
      itemIds: [],
      isDisableChioce: true,
      optionsData: [],
    };
  },
  watch: {},
  created() {
    this.loadList();
    this.loadListRole();
    this.loadRolesList();
  },
  methods: {
    ChangeStatus(row) {
      this.$confirm("请确认是否调整账户状态为停用/启用？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          putUser({ id: row.id, status: row.status }).then((res) => {
            if (res.success) {
              this.getUserList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
          this.getUserList();
        });
    },
    loadListRole() {
      getRoleBaseInfos().then((res) => {
        if (res.success) {
          this.RoleBaseList = res.result;
        }
      });
    },
    loadRolesList() {
      roleGetAll().then((res) => {
        if (res.success) {
          this.rolesList = res.result;
        }
      });
    },
    loadOrganizinList() {
      getDepartmentList().then((res) => {
        if (res.success) {
          this.OrganizinList = res.result;
        }
      });
    },
    rolesId(val) {
      this.roleId = val;
    },
    handleClick(tab, event) {
      if (tab.name == "frist") {
        this.getSysUserIndexOptions();
      }
    },
    saveUser(formName) {
      if (this.isBulk) {
        this.userData.depIds = this.$refs.SlotOrgList.getCheckedKeys();
        if (this.userData.depIds.length > 1) {
          this.$message.error("组织框架只能选一个");
          return false;
        } else if (this.userData.depIds.length == 0) {
          this.$message.error("组织框架必填");
          return false;
        }
        this.saveLoadingUser = true;
        userBatchShiftDept({
          deptId: this.userData.depIds[0],
          userIds: this.itemIds,
        }).then((res) => {
          if (res.success) {
            this.$message.success("操作成功");
            this.itemIds = [];
            this.dialogVisibleUser = false;
            this.isBulk = false;
            this.activeName = "first";
            this.getUserList();
            this.isTree = true;
          }
          this.saveLoadingUser = false;
        });
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.userData.depIds = this.$refs.SlotOrgList.getCheckedKeys();
            if (this.userData.depIds.length == 0) {
              this.$message.error("请先选择组织框架");
              return false;
            }
            if (this.userData.depIds.length > 1) {
              this.$message.error("组织框架只能选一个");
              return false;
            }
            this.saveLoadingUser = true;
            addUser(this.userData).then((res) => {
              if (res.success) {
                this.$message.success("操作成功");
                this.resetForm("userData");
                this.dialogVisibleUser = false;
                this.isBulk = false;
                this.activeName = "first";
                this.getUserList();
                this.isTree = true;
              }
              this.saveLoadingUser = false;
            });
          }
        });
      }
    },
    addNew(type) {
      this.needPwd = false;
      this.disabled = false;
      this.dialogVisibleUser = true;
      this.userData.id = "";
      this.loadUserList();
      this.getSysUserIndexOptions();
      this.userData.roleIds = [];
      this.userData.depIds = [];
      this.loadOrganizinList();
      if (type == "bulk") {
        this.isBulk = true;
        this.activeName = "third";
        this.titNameUser = "批量转移组织";
      } else {
        this.isBulk = false;
        this.activeName = "first";
        this.titNameUser = "新增用户";
      }
    },
    getUserList(val) {
      window.scrollTo(0, 0);
      this.listLoading = true;
      const _data = {
        page: {
          current: this.pagination.pageNo ? this.pagination.pageNo : 1,
          size: this.pagination.pageSize ? this.pagination.pageSize : 10,
        },
        id: this.clickId,
        type: this.type,
        roleIds: this.roleIds,
      };
      getUserList(_data).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result.records;
          this.pagination.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.pagination.pageSize = val;
      this.pagination.pageNo = 1;
      this.getUserList();
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.pagination.pageNo = val;
      this.getUserList();
    },
    handleClose(done) {
      done();
      this.resetForm("createDate");
    },
    handleCloseUser(done) {
      done();
      this.isBulk = false;
      this.resetForm("userData");
      this.activeName = "first";
    },
    cancel() {
      this.dialogVisible = false;
      this.resetForm("createDate");
    },
    cancelUser() {
      this.dialogVisibleUser = false;
      this.resetForm("userData");
      this.activeName = "first";
    },
    editOrCreate(data, val) {
      if (val == 1) {
        if (data.type == "子团队") {
          this.$message.error("最多只支持四级！");
          return false;
        }
        this.titName = "新增子节点";
        this.createDate.id = "";
        this.createDate.parentId = data.id;
        if (data.type == "集团") {
          this.createDate.type = "地区";
        } else if (data.type == "地区") {
          this.createDate.type = "团队";
        } else if (data.type == "团队") {
          this.createDate.type = "子团队";
        }
      } else if (val == 2) {
        this.titName = "编辑";
        this.createDate.id = data.id;
        getDepById({ id: data.id }).then((res) => {
          if (res.success) {
            this.createDate.name = res.result.name;
            this.createDate.needKpi = res.result.needKpi;
            this.createDate.parentId = res.result.parentId;
            this.createDate.managerId = res.result.managerId;
            this.createDate.type = res.result.type;
            this.createDate.version = res.result.version;
            this.createDate.needStatistics = res.result.needStatistics;
            this.createDate.businessType = res.result.businessType;
          }
        });
      } else if (val == 3) {
        this.titName = "新增节点";
        this.createDate.id = "";
        if (data.type == "集团") {
          this.createDate.parentId = 0;
        } else {
          this.createDate.parentId = data.parentId;
        }
        this.createDate.type = data.type;
      }
      this.dialogVisible = true;

      this.loadUserList();
    },
    search() {
      if (this.filterText == "") {
        this.isTree = true;
        this.loadList();
      } else {
        this.isTree = false;
        serachGroup({ name: this.filterText }).then((res) => {
          if (res.success) {
            this.options = res.result;
          }
        });
      }
    },
    getVal(value, type) {
      this.clickId = value;
      this.type = type;
      this.pagination.pageNo = 1;
      this.getUserList();
    },

    handleNodeClick(node, data, value) {
      this.clickId = node.id;
      this.type = node.type;
      this.pagination.pageNo = 1;
      this.getUserList(node.id);
      this.titleFoo = node.name;
    },
    searchList() {
      this.pagination.pageNo = 1;
      this.loadList();
    },
    loadList() {
      this.listLoading = true;
      OrganizinList().then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.data = res.result;
          this.titleFoo = this.data[0].name;
          this.clickId = res.result[0].id;
          this.type = res.result[0].type;
          this.getUserList();
        }
        this.listLoading = false;
      });
    },
    loadUserList() {
      getUserBaseInfos().then((res) => {
        if (res.success) {
          this.UserBaseList = res.result;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleLoginAs(rowid) {
      //获取该用户的token
      loginAs({ id: rowid }).then((res) => {
        if (res.success == true) {
          Cookies.set("accessToken", res.result);
          this.removeStore("useInfo");
          this.removeStore("avatar");
          this.removeStore("nickName");
          this.removeStore("mobile");
          this.removeStore("u_width");
          this.$store.dispatch("upDateAvatar");
          this.$store.dispatch("upDateUserName");
          this.$store.dispatch("updateCount");
          this.$store.dispatch("upDateMobile");
          getCurrentUser().then((res1) => {
            if (res1.success) {
              let roles = []; //角色可能有多个
              let departments = []; //组织框架可能有多个
              res1.result.roles.forEach((e) => {
                roles.push(e.name);
              });
              res1.result.departments.forEach((e) => {
                departments.push(e.name);
              });
              this.setStore("roles", roles);
              this.setStore("departments", departments);
              this.setStore("permiss", res1.result.permissions);
              delete res1.result.roles;
              delete res1.result.departments;
              this.setStore("useInfo", res1.result);
              location.reload();
            } else {
              this.message.error("获取用户信息失败");
            }
          });
        } else {
          this.message.error("登录该用户失败");
        }
      });
    },
    handleEdit(rid, row) {
      this.isBulk = false;
      this.titNameUser = "编辑用户";
      this.activeName = "first";
      this.roleId = row.roleId;
      this.needPwd = true;
      this.dialogVisibleUser = true;
      this.disabled = true;
      this.userData.id = rid;
      this.userData.depIds = [];
      this.loadOrganizinList();
      this.loadUserList();
      this.getSysUserIndexOptions(rid);
      getUser({ id: rid }).then((res) => {
        if (res.success) {
          this.userData.username = res.result.user.username;
          this.userData.nickName = res.result.user.nickName;
          this.userData.password = res.result.user.password;
          this.userData.version = res.result.user.version;
          this.userData.roleId = res.result.roleId;
          this.userData.depIds = res.result.departmentIds;
          this.userData.managerId = res.result.user.managerId;
          this.userData.path = res.result.path ? res.result.path : "";
        }
      });
    },
    NodeDel(d) {
      //删除节点
      if (d.children && d.children.length !== 0) {
        this.$message.error("此节点有子级，不可删除！");
        return false;
      } else {
        //新增节点可直接删除，已存在的节点要二次确认
        //删除操作
        let DelFun = () => {
          deleteDepById({ id: d.id }).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.loadList();
            }
          });
        };
        //二次确认
        let ConfirmFun = () => {
          this.$confirm("是否删除 " + d.name + "？", "提示", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              DelFun();
            })
            .catch(() => {});
        };
        //判断是否是新增节点
        if (!this.canDel) {
          // 判断节点是否可删除
          ConfirmFun();
        } else {
          this.$message.error("此节点正在被使用，不可删除！");
        }
      }
    },
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.SaveLoading = true;
          addOrganizin(this.createDate).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.dialogVisible = false;
              this.resetForm("createDate");
              this.loadList();
            }
            this.SaveLoading = false;
          });
        } else {
          return false;
        }
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;

      if (val.length) {
        this.isDisableChioce = false;
      } else {
        this.isDisableChioce = true;
      }
      this.itemIds = val.map((i) => i.id);
    },
    //获取当前用户可设置的主页列表
    getSysUserIndexOptions(id) {
      sysUserIndexOptions({ userId: id }).then((res) => {
        this.optionsData = res.result.options;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.filterCon {
  overflow-y: auto;
  height: 80vh;
  .filter-tree {
    span {
      display: inline-block;
      width: 100%;
      line-height: 1;
    }
  }
}
[title] {
  width: 45% !important;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.operator .el-dropdown-link {
  color: white;
}
.el-dropdown {
  position: absolute;
  right: 0;
}
.el-tree-node__content:hover .el-dropdown-link {
  color: #bbb;
}
.el-tree-node {
  overflow-x: auto !important;
  overflow-y: hidden;
  white-space: nowrap;
}
.groupTitle {
  background: #666666;
  padding: 10px;
  color: white;
  margin: 0;
}
.groupContent {
  margin: 0;
}
.groupContent li {
  padding: 10px;
}
.groupContent li:hover {
  background: #cccccc;
}
.wenhao {
  .iconwenhao-xiangsu {
    font-size: 14px;
    color: #bbbbbb;
  }
  &:hover {
    .iconwenhao-xiangsu {
      color: #ff3f3f;
    }
  }
}
</style>
<style>
.setInput .el-input__inner {
  height: 35px !important;
}
.el-col-8 .el-tree-node {
  overflow-x: auto !important;
  overflow-y: hidden;
  white-space: nowrap;
}
.tooltipStyle .tipContent {
  color: #ccc;
}
.tooltipStyle.el-tooltip__popper {
  border: none;
}
</style>